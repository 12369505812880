import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import { gql } from '@apollo/client';
export var OfferingCardDetailsFragmentDoc = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  fragment OfferingCardDetails on Offering {\n    id\n    slug\n    airdrop\n    companyName\n    following\n    description\n    amountRaised\n    externalInvestmentsAmountCents\n    externalInvestmentsAmountTooltip\n    amountRaisedWithExternalCents\n    cardTooltipText\n    cardImageUrl\n    amountRaised\n    badges\n    spotlight\n    minInvestmentAmountCents\n    regulationForDisplay\n    cardComplianceText\n    hostingEntityForDisplay\n    calculatedMinInvestmentAmount\n    logoUrl(resize: { width: 60, height: 60, type: fit })\n    logoUrl2x: logoUrl(resize: { width: 120, height: 120, type: fit })\n    tags {\n      id\n      colorHex\n      displayName\n      slug\n      topLevelSectorTag {\n        colorHex\n        displayName\n        description\n        vertical\n        group\n      }\n      impact\n      vertical\n      group\n    }\n    address {\n      id\n      state\n      city\n    }\n    state\n    flexibleDealTerms {\n      title\n      value\n      nextTierValue\n      showOnTombstone\n      type\n      xValueForDealCard\n      id\n    }\n    xSecurity {\n      xValuationCapCents\n      xValuationCents\n    }\n    timeRemainingValueForDisplay\n    timeRemainingUnitForDisplay\n    timeToInvestBadgeParams {\n      value\n      unit\n    }\n    hasClosed\n    reviewsCount\n    testingTheWaters\n    testingTheWatersFirstDaysOff\n    amountRaisedCents\n    maxGoalReached\n    investorsCount\n    crypto\n    verticalTag\n    trending\n    investmentBadge {\n      id\n      description\n      state\n      amountCents\n    }\n    hostingEntity\n  }\n"])));
export var GdprDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query Gdpr {\n    gdprZone\n  }\n"])));
export var TickerInvestmentsDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query TickerInvestments($first: Int) {\n    tickerInvestments(first: $first) {\n      nodes {\n        id\n        amountCents\n        amountPublic\n        investorFirstName\n        investorLastName\n        avatarUrl(resize: { width: 36, height: 36, type: fill })\n        avatarUrl2x: avatarUrl(resize: { width: 72, height: 72, type: fill })\n        createdAt\n        companyName\n        offeringSlug\n        userSlug\n        userVisibilityPublic\n      }\n    }\n  }\n"])));
export var GetUsOfferingsDocument = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query GetUSOfferings(\n    $first: Int\n    $scopeFilter: OfferingScopeFilterInput\n    $baseScopeFilters: [OfferingBaseScopeEnum!]\n  ) {\n    offerings(\n      scopeFilter: $scopeFilter\n      first: $first\n      baseScopeFilters: $baseScopeFilters\n    ) {\n      edges {\n        node {\n          ...OfferingCardDetails\n        }\n      }\n      pageInfo {\n        hasNextPage\n      }\n    }\n  }\n  ", "\n"])), OfferingCardDetailsFragmentDoc);