import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import styled, { css } from 'styled-components';
import { em } from './css';
export var media = function media(size) {
  var features = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'min';
  return function (first) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    if (features === 'min') {
      return css(["@media screen and (min-width:", "){", "}"], em("".concat(size, "px")), css.apply(void 0, [first].concat(args)));
    }

    if (features === 'max') {
      return css(["@media screen and (max-width:", "){", "}"], em("".concat(size, "px")), css.apply(void 0, [first].concat(args)));
    }
  };
};
export var HideWhen = styled.div.withConfig({
  displayName: "media__HideWhen",
  componentId: "sc-tcxrkn-0"
})(["", ""], function (props) {
  return props.sizes.map(function (size) {
    var breakpoints = props.theme.grid.breakpoints;

    if (breakpoints[size]) {
      var feature = (props === null || props === void 0 ? void 0 : props.feature) || 'min';
      return css(["", ""], media(breakpoints[size], feature)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n          display: none;\n        "]))));
    }

    return null;
  });
});
export var ShowWhen = styled.div.withConfig({
  displayName: "media__ShowWhen",
  componentId: "sc-tcxrkn-1"
})(["display:none;", ""], function (props) {
  return props.sizes.map(function (size) {
    var breakpoints = props.theme.grid.breakpoints;

    if (breakpoints[size]) {
      var feature = (props === null || props === void 0 ? void 0 : props.feature) || 'min';
      return css(["", ""], media(breakpoints[size], feature)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n          display: block;\n        "]))));
    }

    return null;
  });
});