import { IOfferingBadgeNameEnum } from '@r-client/data/graphql-types';
import { getTags, offeringTerms } from '../components/offering-card';
export var isOffering = function isOffering(node) {
  return (node === null || node === void 0 ? void 0 : node.__typename) === 'Offering';
};
export var extractDealProps = function extractDealProps(node) {
  var _node$address, _node$address2, _node$badges, _Number;

  var terms = offeringTerms(node);
  var tags = getTags(node);
  var address = isOffering(node) ? "".concat(node === null || node === void 0 ? void 0 : (_node$address = node.address) === null || _node$address === void 0 ? void 0 : _node$address.city, ", ").concat(node === null || node === void 0 ? void 0 : (_node$address2 = node.address) === null || _node$address2 === void 0 ? void 0 : _node$address2.state) : undefined;
  var badges = isOffering(node) ? node === null || node === void 0 ? void 0 : (_node$badges = node.badges) === null || _node$badges === void 0 ? void 0 : _node$badges.filter(function (b) {
    return b !== IOfferingBadgeNameEnum.Live;
  }) : undefined;
  var investmentBadge = isOffering(node) && node.investmentBadge && node.investmentBadge.description ? {
    description: node.investmentBadge.description,
    amount: (_Number = Number(node.investmentBadge.amountCents)) !== null && _Number !== void 0 ? _Number : 0
  } : undefined;

  if (isOffering(node)) {
    var _node$description, _node$cardImageUrl, _node$logoUrl, _node$logoUrl2x, _node$cardTooltipText, _node$cardComplianceT, _node$timeToInvestBad, _node$timeToInvestBad2;

    return {
      id: +node.id,
      slug: node.slug,
      companyName: node.companyName,
      description: (_node$description = node.description) !== null && _node$description !== void 0 ? _node$description : '',
      cover: (_node$cardImageUrl = node.cardImageUrl) !== null && _node$cardImageUrl !== void 0 ? _node$cardImageUrl : '',
      logoUrl: (_node$logoUrl = node.logoUrl) !== null && _node$logoUrl !== void 0 ? _node$logoUrl : '',
      logoUrl2x: (_node$logoUrl2x = node.logoUrl2x) !== null && _node$logoUrl2x !== void 0 ? _node$logoUrl2x : '',
      tooltipText: (_node$cardTooltipText = node.cardTooltipText) !== null && _node$cardTooltipText !== void 0 ? _node$cardTooltipText : '',
      cardComplianceText: (_node$cardComplianceT = node.cardComplianceText) !== null && _node$cardComplianceT !== void 0 ? _node$cardComplianceT : '',
      leftToInvestValue: (_node$timeToInvestBad = node.timeToInvestBadgeParams) === null || _node$timeToInvestBad === void 0 ? void 0 : _node$timeToInvestBad.value,
      leftToInvestUnit: (_node$timeToInvestBad2 = node.timeToInvestBadgeParams) === null || _node$timeToInvestBad2 === void 0 ? void 0 : _node$timeToInvestBad2.unit,
      following: node.following,
      terms: terms,
      badges: badges,
      tags: tags,
      investmentBadge: investmentBadge,
      location: address,
      spotlight: !!node.spotlight
    };
  }

  return {};
};