export var formatNumberWithCommasAndSuffix = function formatNumberWithCommasAndSuffix(num) {
  var showCurrency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var roundedNum = Math.round(num);
  var million = 1000000;
  var billion = 1000000000;
  var returnValue = null;

  if (roundedNum >= billion) {
    var billions = roundedNum / billion;
    returnValue = "".concat(billions.toFixed(1), "B");
  } else if (roundedNum >= million) {
    var millions = roundedNum / million;
    returnValue = "".concat(millions.toFixed(1), "M");
  } else {
    returnValue = roundedNum.toLocaleString();
  }

  if (showCurrency) {
    returnValue = "\xA3".concat(returnValue);
  }

  return returnValue;
};