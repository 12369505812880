import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { BigNumber } from 'bignumber.js';
import { createFormatter, DEFAULT_CURRENCY, DEFAULT_LOCALE, FormatterFloatDigitsBigNumber } from './intl-formatter';
import { formatAmountWithLetters } from './number';

var formatterWithAmountDigits = function formatterWithAmountDigits(decimalPlaces) {
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_CURRENCY;
  return createFormatter(true, false, decimalPlaces, true, currency);
};

export var formatterFoaltDigitsBigNumberWithCurrencyPrefix = function formatterFoaltDigitsBigNumberWithCurrencyPrefix(currency) {
  var _knownPrefixes$curren;

  var knownPrefixes = {
    EUR: '€',
    GBP: '£',
    USD: '$'
  };
  var currencyPrefix = (_knownPrefixes$curren = knownPrefixes[currency]) !== null && _knownPrefixes$curren !== void 0 ? _knownPrefixes$curren : currency;
  return _objectSpread(_objectSpread({}, FormatterFloatDigitsBigNumber), {}, {
    prefix: currencyPrefix
  });
};
export var FormatterFloatDigitsBigNumberWith$Prefix = formatterFoaltDigitsBigNumberWithCurrencyPrefix('USD'); // TODO: Use round=auto as default setting

export var formatCurrency = function formatCurrency(currency, _amount) {
  var round = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var withLetters = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var decimalPlaces = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 2;
  var letter = '';
  var formatter = formatterFoaltDigitsBigNumberWithCurrencyPrefix(currency);
  var formatterWithAmountRounded = createFormatter(undefined, undefined, undefined, undefined, currency);

  if (_amount instanceof BigNumber) {
    var newAmount$ = _amount;

    if (withLetters) {
      var _formatAmountWithLett = formatAmountWithLetters(_amount, false);

      newAmount$ = _formatAmountWithLett.newAmount;
      letter = _formatAmountWithLett.letter;
    }

    if (round === true) {
      return "".concat(newAmount$.toFormat(0, formatter)).concat(letter);
    }

    if (round === 'auto') {
      return "".concat(newAmount$.toFormat(decimalPlaces, formatter).replace(/\.?0+$/, '')).concat(letter);
    }

    return "".concat(newAmount$.toFormat(decimalPlaces, formatter)).concat(letter);
  }

  var amount = _amount !== null && _amount !== void 0 ? _amount : 0;
  var newAmount = amount;

  if (withLetters) {
    var _formatAmountWithLett2 = formatAmountWithLetters(amount, false);

    newAmount = _formatAmountWithLett2.newAmount;
    letter = _formatAmountWithLett2.letter;
  }

  if (round === 'auto' && Number.isInteger(newAmount) || round === true) {
    return "".concat(formatterWithAmountRounded.format(newAmount)).concat(letter);
  } else if (round === 'auto') {
    return "".concat(formatterWithAmountDigits(decimalPlaces, currency).format(newAmount).replace(/\.?0+$/, '')).concat(letter);
  }

  return "".concat(formatterWithAmountDigits(decimalPlaces, currency).format(newAmount)).concat(letter);
}; // TODO: Use round=auto as default setting

export var formatDollars = function formatDollars(_amount) {
  var round = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var withLetters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var decimalPlaces = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 2;
  return formatCurrency('USD', _amount, round, withLetters, decimalPlaces);
};
var formatFractionalPriceCache = {};
export var formatFractionalPrice = function formatFractionalPrice() {
  var currency = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_CURRENCY;
  var amount = arguments.length > 1 ? arguments[1] : undefined;
  var maximumFractionDigits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  if (amount instanceof BigNumber) {
    return amount.toFormat(maximumFractionDigits, FormatterFloatDigitsBigNumberWith$Prefix);
  }

  var formatter = formatFractionalPriceCache[maximumFractionDigits] || (formatFractionalPriceCache[maximumFractionDigits] = new Intl.NumberFormat(DEFAULT_LOCALE, {
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits,
    style: 'currency'
  }));
  return formatter.format(amount);
};
export var centsToMainUnit = function centsToMainUnit(cents) {
  if (cents instanceof BigNumber) {
    return cents.div(100);
  } else if (typeof cents === 'number') {
    return cents / 100;
  } else {
    return 0;
  }
};
export var mainUnitToCents = function mainUnitToCents(mainUnitAmount) {
  return mainUnitAmount ? mainUnitAmount * 100 : 0;
};
export var centsToDollars = centsToMainUnit;
export var dollarsToCents = mainUnitToCents; // transform $10,100.50 to 10100.5
// based on https://stackoverflow.com/a/59636570/3590952

export var unformatAmount = function unformatAmount(amount) {
  var num = 123456.789;
  var formatToLocale = new Intl.NumberFormat(DEFAULT_LOCALE, {
    style: 'currency',
    currency: DEFAULT_CURRENCY
  });
  var partsLocal = formatToLocale.formatToParts(num);
  var group = '';
  var decimal = '';
  var currency = '';
  partsLocal.forEach(function (i) {
    switch (i.type) {
      case 'group':
        group = i.value;
        break;

      case 'decimal':
        decimal = i.value;
        break;

      case 'currency':
        currency = i.value;
        break;

      default:
        break;
    }
  });
  return parseFloat(amount.replace(new RegExp("\\".concat(group), 'g'), '').replace(new RegExp("\\".concat(decimal)), '.').replace(new RegExp("\\".concat(currency), 'g'), ''));
};
/**
 * According to legal requirements user must have a REG A+ investment limit to make a deal.
 * 10% of the bigger amount (Yearly Income or Net worth)
 */

export function regAInvestmentLimit(netWorth, yearlyIncome) {
  var amount = netWorth === undefined ? yearlyIncome : yearlyIncome === undefined ? netWorth : Math.max(netWorth, yearlyIncome);
  return amount !== undefined ? amount * 0.1 : undefined;
}
export var formatCurrencyFromCents = function formatCurrencyFromCents(currency, amount) {
  var round = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var withLetters = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var decimalPlaces = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 2;
  return formatCurrency(currency, centsToMainUnit(amount), round, withLetters, decimalPlaces);
};
export var formatDollarsFromCents = function formatDollarsFromCents(amount) {
  var round = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var withLetters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var decimalPlaces = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 2;
  return formatCurrencyFromCents('USD', amount, round, withLetters, decimalPlaces);
};