import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { BigNumber } from 'bignumber.js';
import { createFormatter, FormatterFloatDigitsBigNumber } from './intl-formatter';
export var formatAmount = function formatAmount() {
  var amount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var round = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var decimalPlaces = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'auto';
  var defaultDecimalPlaces = round ? 0 : 2;
  decimalPlaces = decimalPlaces === 'auto' ? defaultDecimalPlaces : decimalPlaces;
  return createFormatter(false, round, decimalPlaces).format(amount);
};
export var formatAmountWithLetters = function formatAmountWithLetters(amount) {
  var round = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'auto';
  var decimalPlaces = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  var letter = '';
  var newAmount = amount;

  if (amount instanceof BigNumber) {
    if (amount.div(1000000).gte(1)) {
      newAmount = amount.div(1000000);
      letter = 'M';
    } else if (amount.div(1000).gte(1)) {
      newAmount = amount.div(1000);
      letter = 'K';
    }

    if (round && newAmount instanceof BigNumber) {
      newAmount = new BigNumber(newAmount.toFormat(round === 'auto' ? decimalPlaces : 0));
    }
  } else if (typeof amount === 'number') {
    if (amount / 1000000 >= 1) {
      newAmount = amount / 1000000;
      letter = 'M';
    } else if (amount / 1000 >= 1) {
      newAmount = amount / 1000;
      letter = 'K';
    }

    if (round && typeof newAmount === 'number') {
      newAmount = Number(round === 'auto' ? createFormatter(false, false, decimalPlaces).format(newAmount) : createFormatter(false, true).format(newAmount));
    }
  }

  return {
    newAmount: newAmount,
    letter: letter,
    formatted: "".concat(newAmount).concat(letter)
  };
};
export var formatDecimalPercent = function formatDecimalPercent(value) {
  var withSymbol = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var decimalPlaces = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  var percent;

  if (value instanceof BigNumber) {
    percent = new BigNumber(value.multipliedBy(100).toFormat(decimalPlaces, _objectSpread(_objectSpread({}, FormatterFloatDigitsBigNumber), {}, {
      groupSeparator: ''
    })));
  } else if (typeof value === 'number') {
    percent = Number(createFormatter(false, false, decimalPlaces, false).format(value * 100));
  } else {
    return '';
  }

  var isPositive = value instanceof BigNumber ? value.isPositive() && !value.isZero() : value > 0;
  return withSymbol ? "".concat(isPositive ? '+' : '').concat(percent, "%") : percent;
};