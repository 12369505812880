import emberFund from '../assets/emberfund.png';
import { ReactComponent as EmberFundLogo } from '../assets/emberfund.svg';
import emberFund2x from '../assets/emberfund-2x.png';
import emberFundBg from '../assets/emberfund-bg.png';
import emberfundBgMd from '../assets/emberfund-bg-md.png';
import emberFundBgXs from '../assets/emberfund-bg-xs.png';
import gumroad from '../assets/gumroad.png';
import { ReactComponent as GumroadLogo } from '../assets/gumroad.svg';
import gumroad2x from '../assets/gumroad-2x.png';
import gumroadBg from '../assets/gumroad-bg.png';
import gumroadBgMd from '../assets/gumroad-bg-md.png';
import gumroadBgXs from '../assets/gumroad-bg-xs.png';
import maven from '../assets/maven.png';
import { ReactComponent as MavenLogo } from '../assets/maven.svg';
import maven2v from '../assets/maven-2x.png';
import mavenBg from '../assets/maven-bg.png';
import mavenBgMd from '../assets/maven-bg-md.png';
import mavenBgXs from '../assets/maven-bg-xs.png';
export var CONTENT_US = [{
  name: 'Ember Fund',
  bgImg: emberFundBg,
  bgImgXs: emberFundBgXs,
  bgImgMd: emberfundBgMd,
  logoUrl: emberFund,
  logoUrl2x: emberFund2x,
  logoXsUrl: {
    type: 'svg',
    icon: EmberFundLogo
  },
  bgColor: 'white',
  bgSize: '330px 262px',
  bgXsSize: '57%',
  bgMdSize: '67% 100%',
  bgPosition: 'top center',
  bgXsPosition: 'bottom right',
  bgMdPosition: 'bottom right',
  textColor: 'black',
  linearGradient: 'linear(to-b, rgba(254, 255, 250, 0) 33.04%, #FEFFFA 49.04%)',
  description: 'Funded & closed July 2022',
  title: '10x in one year',
  subTitle: '12 months after their Republic campaign, Ember Fund secured a $5.3M seed round at 10x higher valuation.',
  subTitleWidth: 317,
  feats: [{
    title: 'Investors',
    value: '1,859'
  }, {
    title: 'Markup',
    value: '1000%'
  }, {
    title: 'Time',
    value: '12 mo.'
  }],
  buttonBdColor: 'rgba(0, 0, 0, 0.1)',
  buttonBgColor: 'white',
  href: '/case-studies/ember-fund-case-study'
}, {
  name: 'Gumroad',
  bgImg: gumroadBg,
  bgImgXs: gumroadBgXs,
  bgImgMd: gumroadBgMd,
  logoUrl: gumroad,
  logoUrl2x: gumroad2x,
  logoXsUrl: {
    type: 'svg',
    icon: GumroadLogo
  },
  bgSize: '330px 262px',
  bgColor: 'black',
  bgPosition: 'top center',
  bgXsPosition: 'top 10% right',
  bgMdPosition: 'top 55% right',
  bgXsSize: '60%',
  bgMdSize: '70%',
  textColor: 'white',
  linearGradient: 'linear(to-b, rgba(0, 0, 0, 0) 0%, #000000 62.16%)',
  description: 'Funded & closed April 2021',
  title: '$5M raised in 12 hours',
  subTitle: 'Gumroad, a platform built to empower creators, welcomed 7,000+ community members onto their cap table alongside top VCs.',
  subTitleWidth: 362,
  feats: [{
    title: 'Investors',
    value: '7,202'
  }, {
    title: 'Raised',
    value: '$5M'
  }, {
    title: 'Time',
    value: '12 hrs.'
  }],
  buttonBdColor: 'rgba(225, 225, 225, 0.3)',
  buttonBgColor: 'transparent',
  href: '/case-studies/gumroad-case-study'
}, {
  name: 'Maven',
  bgImg: mavenBg,
  bgImgXs: mavenBgXs,
  bgImgMd: mavenBgMd,
  logoUrl: maven,
  logoUrl2x: maven2v,
  logoXsUrl: {
    type: 'svg',
    icon: MavenLogo
  },
  bgSize: '183px 197px',
  bgXsSize: '30%',
  bgMdSize: '38%',
  bgColor: 'brand.green.900',
  bgPosition: 'center top 10%',
  bgXsPosition: 'center right',
  bgMdPosition: 'center right 50px',
  textColor: 'white',
  description: 'Funded & closed January 2021',
  title: 'Experienced founders raised from their community first',
  subTitle: 'Republic investors became Maven’s early customers and just three months later, Maven raised a $20M Series A led by a16z.',
  subTitleWidth: 337,
  feats: [{
    title: 'Investors',
    value: '479'
  }, {
    title: 'Raised',
    value: '$750k'
  }, {
    title: 'Time',
    value: '1 mo.'
  }],
  buttonBdColor: 'rgba(225, 225, 225, 0.1)',
  buttonBgColor: 'transparent',
  href: '/case-studies/maven-case-study'
}];