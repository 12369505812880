import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from 'styled-components';
import { media } from '../../utils/media';
import { rem } from '../../utils/styles';
export var Wrapper = styled.div.withConfig({
  displayName: "style__Wrapper",
  componentId: "sc-14okau4-0"
})(["display:flex;justify-content:", ";"], function (props) {
  return props.justifyCenter ? 'center' : 'space-between';
});
export var InformationNotice = styled.p.withConfig({
  displayName: "style__InformationNotice",
  componentId: "sc-14okau4-1"
})(["text-align:center;color:", ";margin-bottom:", ";font-size:", ";"], function (props) {
  return props.theme.colors.neutral.middark;
}, function (props) {
  return rem(props.theme.spacing.md);
}, rem(14));
export var Provider = styled.div.withConfig({
  displayName: "style__Provider",
  componentId: "sc-14okau4-2"
})(["text-align:center;font-size:", ";"], rem(14));
export var ReserveCarouselSpace = styled.div.withConfig({
  displayName: "style__ReserveCarouselSpace",
  componentId: "sc-14okau4-3"
})(["height:", "px;opacity:", ";transition:opacity 0.5s ease-in-out;", ""], function (props) {
  return props.mobile;
}, function (props) {
  return props.isLoading ? '0' : '1';
}, function (props) {
  return media(props.theme.grid.breakpoints.md)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    height: ", "px;\n  "])), props.desktop);
});
ReserveCarouselSpace.defaultProps = {
  isLoading: false
};
export var CarouselPlaceholderBg = styled.div.withConfig({
  displayName: "style__CarouselPlaceholderBg",
  componentId: "sc-14okau4-4"
})(["background-color:", ";"], function (props) {
  return props.theme.colors.neutral.xlight;
}); // this is a placeholder for the height of the carousel indicators

export var CarouselSeparator = styled.div.withConfig({
  displayName: "style__CarouselSeparator",
  componentId: "sc-14okau4-5"
})(["margin-bottom:", ";"], rem(58));