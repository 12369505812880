import _toConsumableArray from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import styled, { css } from 'styled-components';
import { media } from '../../../utils/media';

var pxToRem = function pxToRem(pixels) {
  var base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
  return "".concat(pixels / base, "rem");
};

export var CarouselWrapper = styled.div.withConfig({
  displayName: "style__CarouselWrapper",
  componentId: "sc-wxpolr-0"
})(["", ";position:relative;min-height:", ";", ""], function (props) {
  return props.isHomepageHeader ? "\n        display: flex;\n        justify-content: center;\n      " : '';
}, pxToRem(50), function (props) {
  return props.showDots ? 'margin-bottom: 30px;' : '';
});
export var ItemsContainer = styled.div.withConfig({
  displayName: "style__ItemsContainer",
  componentId: "sc-wxpolr-1"
})(["", ";", " ", ""], function (props) {
  return props.isHomepageHeader ? 'min-width: 100%;' : 'width: 100%';
}, function (props) {
  return (// Hide rowsPerSlide value in irrelevant css property
    Object.keys(props.rowsPerSlide).reduce(function (acc, size) {
      return css(["", " ", ""], acc, media(props.theme.grid.breakpoints[size])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n          order: ", ";\n        "])), props.rowsPerSlide[size]));
    }, css([""]))
  );
}, function (props) {
  return props.hide ? "\n  display: none;\n  flex-wrap: wrap;\n    " : "\n  display: flex;\n  overflow: hidden;\n    ";
});
export var TrackContainer = styled.div.withConfig({
  displayName: "style__TrackContainer",
  componentId: "sc-wxpolr-2"
})(["width:100%;overflow:hidden;"]);
// Use attrs to directly set styles for dragging performance
export var Track = styled.div.attrs(function (props) {
  return {
    style: {
      // Hack for IE11 which doesn't support calc in translateX
      // Luckily translateX stacks, so instead of translateX(calc(x * -100%)),
      // we repeat translate(-100%) x times
      transform: "translateX(".concat(props.drag, "px) ").concat(_toConsumableArray(Array(props.animate + props.offset)).map(function () {
        return 'translateX(-100%)';
      }).join(' ')),
      transition: "transform ".concat(props.transition ? props.duration : 0, "s")
    }
  };
}).withConfig({
  displayName: "style__Track",
  componentId: "sc-wxpolr-3"
})(["display:flex;"]);
export var Slide = styled.div.withConfig({
  displayName: "style__Slide",
  componentId: "sc-wxpolr-4"
})(["width:100%;flex-shrink:0;position:relative;display:flex;flex-wrap:wrap;visibility:", ";"], function (props) {
  return props.show ? 'visible' : 'hidden';
});
export var IndicatorArrow = styled.button.withConfig({
  displayName: "style__IndicatorArrow",
  componentId: "sc-wxpolr-5"
})(["position:absolute;display:flex;justify-content:center;margin:auto;bottom:0;top:0;z-index:2;width:", ";height:", ";border-radius:50%;border:", " solid ", ";background-color:white;cursor:pointer;outline:none;", ";&:hover{background-color:", ";}&:focus{box-shadow:0 0 4px 0 ", ";}svg{height:", ";width:", ";position:absolute;top:23.5%;", ";}"], pxToRem(50), pxToRem(50), pxToRem(1), function (props) {
  return props.theme.colors.neutral.midlight;
}, function (props) {
  if (props.left) {
    return css(["left:2px;"]);
  } else {
    return css(["right:2px;"]);
  }
}, function (props) {
  return props.theme.colors.neutral.xlight;
}, function (props) {
  return props.theme.colors.neutral.middark;
}, pxToRem(25), pxToRem(25), function (props) {
  if (props.left) {
    return css(["left:20.5%;"]);
  } else {
    return css(["right:20.5%;"]);
  }
});
export var Dots = styled.ul.withConfig({
  displayName: "style__Dots",
  componentId: "sc-wxpolr-6"
})(["position:absolute;display:", ";padding:0;", " width:", ";margin:23px 0;list-style:none;justify-content:center;", ""], function (props) {
  return props.showArrows ? 'none' : 'flex';
}, function (props) {
  return props.overBanner ? 'bottom: 0px;' : '';
}, function (props) {
  return props.isHomepageHeader ? 'fit-content' : '100%';
}, function (props) {
  return media(props.theme.grid.breakpoints.md)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    display: flex;\n  "])));
});
export var Dot = styled.li.withConfig({
  displayName: "style__Dot",
  componentId: "sc-wxpolr-7"
})(["width:", ";height:", ";padding:0;cursor:pointer;position:relative;button{font-size:0;line-height:0;display:block;width:", ";height:", ";cursor:pointer;color:transparent;background-color:transparent;border:none;}&.active:after{background-color:", ";}&:hover:after{background-color:", ";}&:after{content:'';display:block;width:10px;height:10px;border:1px solid ", ";position:absolute;top:50%;left:50%;border-radius:50%;transform:translate(-50%,-50%);pointer-events:none;}"], pxToRem(28), pxToRem(28), pxToRem(28), pxToRem(28), function (props) {
  return props.theme.colors.neutral.midlight;
}, function (props) {
  return props.theme.colors.neutral.midlight;
}, function (props) {
  return props.theme.colors.neutral.midlight;
});