export var getSectorTagsWithUniqueParents = function getSectorTagsWithUniqueParents(offering) {
  var _offering$tags$filter, _offering$tags;

  return (_offering$tags$filter = (_offering$tags = offering.tags) === null || _offering$tags === void 0 ? void 0 : _offering$tags.filter(function (tag, index, tagsArr) {
    var sectorTag = tag.topLevelSectorTag;
    var hasUniqueSectorParent = false;

    if (sectorTag) {
      hasUniqueSectorParent = tagsArr.findIndex(function (t) {
        var _t$topLevelSectorTag;

        return ((_t$topLevelSectorTag = t.topLevelSectorTag) === null || _t$topLevelSectorTag === void 0 ? void 0 : _t$topLevelSectorTag.displayName) === sectorTag.displayName;
      }) === index;
    }

    return hasUniqueSectorParent;
  })) !== null && _offering$tags$filter !== void 0 ? _offering$tags$filter : [];
};
export var isTeaserPage = function isTeaserPage(offering) {
  return offering.__typename === 'TeaserPage';
};
export var getTags = function getTags(offering) {
  var _offering$tags$filter2, _offering$tags2, _sectorTagsWithUnique;

  if (isTeaserPage(offering)) {
    return offering.tag ? [offering.tag] : [];
  }

  var sectorTagsWithUniqueParents = getSectorTagsWithUniqueParents(offering);
  var impactTags = (_offering$tags$filter2 = (_offering$tags2 = offering.tags) === null || _offering$tags2 === void 0 ? void 0 : _offering$tags2.filter(function (tag) {
    return tag === null || tag === void 0 ? void 0 : tag.impact;
  })) !== null && _offering$tags$filter2 !== void 0 ? _offering$tags$filter2 : [];
  var tags = (_sectorTagsWithUnique = sectorTagsWithUniqueParents.concat(impactTags).map(function (tag) {
    var sectorTag = tag === null || tag === void 0 ? void 0 : tag.topLevelSectorTag;
    var ret = {
      displayName: sectorTag ? sectorTag === null || sectorTag === void 0 ? void 0 : sectorTag.displayName : tag.displayName || (tag === null || tag === void 0 ? void 0 : tag.id),
      colorHex: sectorTag ? '#eee' : tag.colorHex || ''
    };
    return ret;
  })) !== null && _sectorTagsWithUnique !== void 0 ? _sectorTagsWithUnique : [];

  if (offering.crypto) {
    tags.unshift({
      displayName: 'crypto',
      colorHex: '#161d2d'
    });
  }

  return tags.slice(0, 3);
};