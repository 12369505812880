export var cardDataFromSeedrsRecord = function cardDataFromSeedrsRecord(record) {
  var investmentRaised = getValue(record['investment_raised'], {});
  var target = getValue(record['investment_sought'], {});
  var valuation = getValue(record['pre_money_valuation'], {});
  var address = getValue(record['address'], {});
  var images = getImages(record);
  return {
    slug: getValue(record['slug'], ''),
    coverImage: images.coverImage,
    logo: images.logo,
    title: getValue(record['name'], ''),
    description: getValue(record['description'], ''),
    currency: getValue(record['currency'], ''),
    raisedAmountCents: getValue(investmentRaised['amount_in_cents'], 0),
    targetAmountCents: getValue(target['amount_in_cents'], 0),
    preMoneyValuationCents: getValue(valuation['amount_in_cents'], 0),
    numberOfInvestors: getValue(record['number_of_investors'], 0),
    percentageRaised: getValue(record['percentage_amount_raised'], '0'),
    countryCode: getValue(address['country'], ''),
    city: getValue(address['city'], ''),
    type: getValue(record['type'], ''),
    typeForDisplay: getTypeForDisplay(record['type']),
    status: getValue(record['status'], '')
  };
};

var getValue = function getValue(value, fallback) {
  return typeof value === typeof fallback ? value : fallback;
};

var getImages = function getImages(record) {
  var coverImage = getValue(record['cover_image'], {});
  var logo = getValue(record['logo'], {});
  var result = {
    coverImage: getValue(coverImage['cropped_location'], ''),
    logo: getValue(logo['cropped_location'], '')
  };
  Object.keys(result).forEach(function (key) {
    result[key] = result[key].replace(/\\u0026/g, '&');
  });
  return result;
};

var getTypeForDisplay = function getTypeForDisplay(type) {
  if (typeof type !== 'string') {
    return '';
  }

  var humanFriendlyType = {
    EquityCampaign: 'Equity',
    ConvertibleCampaign: 'Convertible'
  };
  return humanFriendlyType[type] || '';
};