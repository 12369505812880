import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import { gql } from '@apollo/client';
export var CarouselBannerContentsFragmentDoc = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  fragment CarouselBannerContents on BannerContent {\n    id\n    isActive\n    title\n    priority\n    theme\n    overlayTheme\n    overlayColor\n    overlayOpacity\n    link\n    logo\n    logoAltText\n    description\n    backgroundImageDesktop\n    backgroundImageAltText\n    backgroundImageMobile\n    foregroundImageDesktop\n    foregroundImageMobile\n    ctaText\n    ctaTheme\n    pillText\n    openLinkInNewTab\n    termsAndConditions\n  }\n"])));
export var CarouselContentsFragmentDoc = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  fragment CarouselContents on Carousel {\n    id\n    jurisdiction\n    settings\n    bannerContents {\n      ...CarouselBannerContents\n    }\n  }\n  ", "\n"])), CarouselBannerContentsFragmentDoc);
export var CarouselsDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query Carousels($slug: String) {\n    carousels(slug: $slug) {\n      nodes {\n        ...CarouselContents\n      }\n    }\n  }\n  ", "\n"])), CarouselContentsFragmentDoc);