export var DEFAULT_LOCALE = 'en-US';
export var DEFAULT_CURRENCY = 'USD';
var formatterFloatDigitsCache = {};
export var createFormatterFloatDigits = function createFormatterFloatDigits(rounded) {
  var _formatterFloatDigits;

  return (_formatterFloatDigits = formatterFloatDigitsCache[rounded]) !== null && _formatterFloatDigits !== void 0 ? _formatterFloatDigits : formatterFloatDigitsCache[rounded] = new Intl.NumberFormat(DEFAULT_LOCALE, {
    maximumFractionDigits: rounded,
    minimumFractionDigits: rounded
  });
};
export var FormatterFloatDigitsBigNumber = {
  groupSize: 3,
  groupSeparator: ',',
  decimalSeparator: '.'
};
var formatterCache = {};
export var createFormatter = function createFormatter() {
  var _formatterCache$cache;

  var withCurrency = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  var rounded = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var decimalPlaces = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  var useGrouping = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var currency = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : DEFAULT_CURRENCY;
  var cacheKey = "".concat(withCurrency ? "currency_".concat(currency, "_") : '').concat(rounded ? 'rounded_' : '').concat(decimalPlaces, "d").concat(useGrouping ? '_grp' : '');
  return (_formatterCache$cache = formatterCache[cacheKey]) !== null && _formatterCache$cache !== void 0 ? _formatterCache$cache : formatterCache[cacheKey] = new Intl.NumberFormat(DEFAULT_LOCALE, {
    currency: withCurrency ? currency : undefined,
    minimumFractionDigits: rounded ? 0 : decimalPlaces,
    maximumFractionDigits: rounded ? 0 : decimalPlaces,
    style: withCurrency ? 'currency' : 'decimal',
    useGrouping: useGrouping
  });
};