export var comparePriority = function comparePriority(a, b) {
  var _a$priority, _b$priority;

  var priorityA = (_a$priority = a.priority) !== null && _a$priority !== void 0 ? _a$priority : 0;
  var priorityB = (_b$priority = b.priority) !== null && _b$priority !== void 0 ? _b$priority : 0;
  var comparison = 0;

  if (priorityA > priorityB) {
    comparison = 1;
  } else if (priorityA < priorityB) {
    comparison = -1;
  }

  return comparison;
};