import { getColors } from './colors';

/** grid */
export var grid = {
  outerMargin: 32,
  gutter: 8,
  totalCols: 12,
  container: {
    xs: 300,
    sm: 768,
    md: 1024,
    lg: 1280
  },
  breakpoints: {
    xs: 0,
    sm: 300,
    md: 768,
    lg: 1024
  }
};
/** spacing */

export var spacing = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32
};
export var getTheme = function getTheme(themeName) {
  var colors = getColors(themeName);
  return {
    colors: colors,
    name: themeName,
    grid: grid,
    spacing: spacing
  };
};