import _slicedToArray from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { useMediaQuery, useTheme } from '@chakra-ui/react';
export var useMobile = function useMobile() {
  var theme = useTheme();

  var _useMediaQuery = useMediaQuery("(max-width: ".concat(theme.breakpoints.sm, ")")),
      _useMediaQuery2 = _slicedToArray(_useMediaQuery, 1),
      isMobile = _useMediaQuery2[0];

  return isMobile;
};