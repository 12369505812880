import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled, { css } from 'styled-components';
import { media } from '../../../utils/media';
export var Wrapper = styled.div.withConfig({
  displayName: "carousel-item-wrapper__Wrapper",
  componentId: "sc-1knu7mo-0"
})(["width:100%;flex-shrink:0;", " position:relative;overflow:hidden;", ""], function (props) {
  if (props.height) {
    return css(["height:", " !important;"], props.height);
  } else {
    return css(["height:35rem;", ""], media(props.theme.grid.breakpoints.md)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n          height: ", ";\n        "])), props.desktopHeight || '17.125rem'));
  }
}, function (props) {
  if (props.backgroundColor) {
    return css(["background-color:", ";"], props.backgroundColor);
  }
});