export function rem(px) {
  var base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
  var pixels = typeof px === 'number' ? px : parseFloat(px);
  return "".concat(pixels / base, "rem");
}
export function em(px) {
  var base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
  var pixels = typeof px === 'number' ? px : parseFloat(px);
  return "".concat(pixels / base, "em");
}

function pxOrString(a) {
  if (typeof a === 'string') {
    return a;
  }

  return "".concat(a, "px");
}

function spacing(type, a, b, c, d) {
  var ret = "".concat(type, ":").concat(pxOrString(a));

  if (b !== undefined) {
    ret += " ".concat(pxOrString(b));
  }

  if (c !== undefined) {
    ret += " ".concat(pxOrString(c));
  }

  if (d !== undefined) {
    ret += " ".concat(pxOrString(d));
  }

  return ret + ';';
}

export function padding() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  return spacing.apply(void 0, ['padding'].concat(args));
}
export function margin() {
  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }

  return spacing.apply(void 0, ['margin'].concat(args));
}
export function transitions() {
  for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
    args[_key3] = arguments[_key3];
  }

  return "transition:".concat(args.join(','), ";");
}