// TODO: MK make sure lodash will work(it was fast-memoize before)
import memoize from 'lodash/memoize';
import { common } from './common';
import { investor } from './investor';
export var colors = memoize(function colors(color, tone) {
  return function (_ref) {
    var theme = _ref.theme;
    return theme.colors[color !== null && color !== void 0 ? color : 'primary'][tone !== null && tone !== void 0 ? tone : 'base'];
  };
});
export { common, investor };