import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

/* eslint-disable no-debugger */
import styled, { css } from 'styled-components';
import { media } from '../../../utils/media';
import { padding, rem } from '../../../utils/css'; // Corrects the return type of Object.keys

function getKeys(obj) {
  return Object.keys(obj);
}

export var Grid = styled.div.withConfig({
  displayName: "grid__Grid",
  componentId: "sc-lmzyzt-0"
})(["margin-right:auto;margin-left:auto;", " ", ""], function (p) {
  // debugger;
  return (p.fluid || p.padded) && css(["", ""], padding(0, rem("".concat(p.theme.grid.outerMargin, "px")), 0, rem("".concat(p.theme.grid.outerMargin, "px"))));
}, function (p) {
  return !p.fluid && css(["", ""], getKeys(p.theme.grid.breakpoints).map(function (bp) {
    return css(["", ""], media(p.theme.grid.breakpoints[bp])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n            max-width: ", ";\n          "])), rem("".concat(p.theme.grid.container[bp], "px"))));
  }));
});
export var Row = styled.div.withConfig({
  displayName: "grid__Row",
  componentId: "sc-lmzyzt-1"
})(["display:flex;flex-flow:row wrap;box-sizing:border-box;", ""], function (p) {
  // debugger;
  return getKeys(p.theme.grid.breakpoints).reduce(function (memo, bp) {
    var breakpointProp = p[bp];

    if (breakpointProp) {
      return css(["", " ", ""], memo, media(p.theme.grid.breakpoints[bp])(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n            ", "\n            ", "\n        "])), function () {
        switch (true) {
          case breakpointProp.justifySpaceBetween:
            return 'justify-content: space-between;';

          case breakpointProp.justifySpaceAround:
            return 'justify-content: space-around;';

          case breakpointProp.justifyFlexStart:
            return 'justify-content: flex-start;';

          case breakpointProp.justifyFlexEnd:
            return 'justify-content: flex-end;';

          case breakpointProp.justifyEnd:
            return 'justify-content: end;';

          case breakpointProp.justifyStart:
            return 'justify-content: start;';

          case breakpointProp.justifyLeft:
            return 'justify-content: left;';

          case breakpointProp.justifyRight:
            return 'justify-content: right;';

          case breakpointProp.justifyCenter:
            return 'justify-content: center;';
        }
      }, function () {
        switch (true) {
          case breakpointProp.alignBaseline:
            return 'align-items: baseline;';

          case breakpointProp.alignCenter:
            return 'align-items: center;';

          case breakpointProp.alignStart:
            return 'align-items: start;';

          case breakpointProp.alignEnd:
            return 'align-items: end;';

          case breakpointProp.alignflexStart:
            return 'align-items: flex-start;';

          case breakpointProp.alignFlexEnd:
            return 'align-items: flex-end;';

          case breakpointProp.alignLeft:
            return 'align-items: left;';

          case breakpointProp.alignRight:
            return 'align-items: right';

          case breakpointProp.alignStretch:
            return 'align-items: stretch';
        }
      }));
    } else {
      return memo;
    }
  }, css([""]));
});
export var Col = styled.div.withConfig({
  displayName: "grid__Col",
  componentId: "sc-lmzyzt-2"
})(["box-sizing:border-box;flex:0 0 auto;", " ", ""], function (p) {
  return padding(0, rem("".concat(p.theme.grid.gutter, "px")), 0, rem("".concat(p.theme.grid.gutter, "px")));
}, function (p) {
  return getKeys(p.theme.grid.breakpoints).map(function (bp) {
    var breakpointColumns = p[bp];

    if (breakpointColumns !== undefined) {
      if (breakpointColumns === false) {
        return css(["display:none;"]);
      } else {
        return css(["", ""], media(p.theme.grid.breakpoints[bp])(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n              flex-basis: ", "%;\n              max-width: ", "%;\n              display: block;\n            "])), 100 / p.theme.grid.totalCols * breakpointColumns, 100 / p.theme.grid.totalCols * breakpointColumns));
      }
    }
  });
});