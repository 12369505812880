import _slicedToArray from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from 'react';
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';
export function useLazyImage(src) {
  var _React$useState = React.useState(''),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loadedSrc = _React$useState2[0],
      setLoadedSrc = _React$useState2[1];

  useIsomorphicLayoutEffect(function () {
    var imageLoader = new Image();

    imageLoader.onload = function () {
      return setLoadedSrc(src);
    };

    imageLoader.src = src;
  }, [src]);

  var setNewSrc = function setNewSrc(newSrc) {
    return setLoadedSrc(newSrc);
  };

  return [loadedSrc, setNewSrc];
}