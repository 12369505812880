import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as colors from '../../colors';
export var getColors = function getColors(themeName) {
  if (themeName !== 'investor') {
    /*eslint-disable-next-line no-console*/
    console.warn("Color palette for \"".concat(themeName, "\" not found, defaulting to investor"));
    themeName = 'investor';
  }

  var _colors$themeName = colors[themeName],
      primary = _colors$themeName.primary,
      secondary = _colors$themeName.secondary;
  return _objectSpread(_objectSpread({}, colors.common), {}, {
    primary: primary,
    secondary: secondary
  });
};